import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, SEO, TeamMember } from 'components';
import { useNavIntro } from 'hooks';
import styles from './styles/team.module.scss';

var TeamPage = function TeamPage(_ref) {
  var _ref$data$page = _ref.data.page,
      navIntro = _ref$data$page.navIntro,
      seo = _ref$data$page.seo,
      teamMembers = _ref$data$page.teamMembers,
      pathname = _ref.location.pathname;
  useNavIntro(navIntro);
  return React.createElement(Fragment, null, React.createElement(Grid, {
    className: styles.container
  }, React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), React.createElement("ul", {
    className: styles.teamList
  }, teamMembers.map(function (_ref2, i) {
    var email = _ref2.email,
        linkedIn = _ref2.linkedIn,
        medium = _ref2.medium,
        name = _ref2.name,
        quickFacts = _ref2.quickFacts,
        portrait = _ref2.portrait,
        role = _ref2.role,
        slug = _ref2.slug,
        twitter = _ref2.twitter;
    return React.createElement("li", {
      key: i,
      className: styles.teamItem
    }, React.createElement(TeamMember, {
      email: email,
      linkedIn: linkedIn,
      medium: medium,
      name: name,
      quickFacts: quickFacts,
      portrait: portrait,
      role: role,
      slug: slug,
      twitter: twitter
    }));
  }))));
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      navIntro: PropTypes.object,
      seo: PropTypes.object.isRequired,
      teamMembers: PropTypes.array
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
export default TeamPage;
export var query = "4163207424";